module.exports = {
	CASH_ADVANCE		: 6,
	CASH_ACCOUNTS		: [1, 2, 3, 4, 5],
	BANKING_ACCOUNTS	: [2, 3, 5],
	PAYABLE_ACCOUNTS	: [18, 19],
	ADVANCE_SETTLEMENT_CLEARING_CODE : '944050',
	OTHER_CLEARING_SUSPENSES_CODE : '944060',
	ACCOUNT_RECEIVABLE : 7,
	ACCOUNT_PAYABLE : 18,
	PURCHASE_TAX : 10,
	PURCHASE_DEPOSITS : [9,17],
	WHD_TAX : 21,
	DISCOUNT : 33,
	RECONCILE_OTHER_ACCOUNTS : [6,7,8,9,10,17,18,19,20,21,22],
	/* PL Order */
	REVENUE : 32,
	COGS : 33,
	GROSS_PROFIT : 100,
	OTHER_REVENUE : 40,
	SALARY_BENEFIT : 34,
	SALE_MARKETING : 35,
	RESEARCH_PRODUCT_DEVELOPMENT : 36,
	LEGAL_PROFESSIONAL_FEE : 37,
	GENERAL_ADMIN_EXPENSE : 38,
	OPERATING_EXPENSE : 101,
	OPERATING_EXPENSES : [34,35,36,37,38,39,41,42,43],
	OPERATING_INCOME_EBITDA : 102,
	DEPRECIATION : 39,
	OTHER_EXPENSE : 41,
	EARNING_BEFORE_INTEREST_AND_TAX_EBIT : 103,
	FINANCING_COST : 42,
	PROFIT_BEFORE_TAX : 104,
	TAX : 43,
	TAX_RELATED_EXPENSE_CODE : '843000',
	PROFIT_FOR_THE_PERIOD : 105,
	/* PL Expense */
	PL_EXPENSES : [33,34,35,36,37,38,39,41,42,43],
	/* Fund Receipt */
	RELATED_EXPENSES : [33,34,35,36,37,38,39,41,42],
	REVENUE_DEFFERED_GRANT_ACCOUNTS : [32,18,19,20,21,22,23,24,25,26,27,28,29,30],
};